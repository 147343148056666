<template>
  <div class="myBreadcrumbBox">
    <div class="title">
      首页 <span>></span> {{ title }} <span>></span> {{ title02 }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "",
      title02: "",
    };
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.myBreadcrumbBox {
  .title {
    font-size: 18px;
    font-family: Microsoft YaHei UI;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }
}
</style>