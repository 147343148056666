<template>
  <div class="homePage08Box" id="homePage08Box">
    <div class="m_content">
      <div class="m_logo">
        <img src="@/assets/images/logo02.png" alt="" />
      </div>
      <div class="dotted_line">
        <img src="@/assets/images/dottedLine.png" alt="" />
      </div>
      <div class="m_menuBox">
        <div class="menuItem" v-for="item in menuList" :key="item.id">
          <div class="menuLabel" @click="$tools.blankPush(item.path)">
            {{ item.label }}
            <div class="line"></div>
          </div>
          <div class="menuDataBox">
            <div class="menuDataLabel" v-for="ele in item.dataList" :key="ele.id" @click="$tools.blankPush(ele.path)">
              <div>
                {{ ele.title }}
                <div class="line_2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dotted_line">
        <img src="@/assets/images/dottedLine.png" alt="" />
      </div>
      <div class="m_menuBottom">
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>WECHAT</p>
              <span>微信公众号：</span>
            </div>
            <el-popover placement="top" trigger="hover">
              <img :src="$tools.localStorageFn.get('PAGE_DATE').wechat" alt="" style="width: 128px; height: 128px" />
              <div slot="reference" class="mr" style="cursor: pointer">
                <img src="@/assets/images/icon_WECHAT.png" alt="" style="width: 31px; height: 26px" />
              </div>
            </el-popover>
          </div>
          <div class="m_bIbottom">扫一扫了解更多信息</div>
        </div>
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>PHONE</p>
              <span>前台总机：</span>
            </div>
            <div class="mr">
              <img src="@/assets/images/icon_PHONE.png" alt="" style="width: 28px; height: 23px" />
            </div>
          </div>
          <div class="m_bIbottom">
            {{ $tools.localStorageFn.get("PAGE_DATE").switchboard }}
          </div>
        </div>
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>ADDRESS</p>
              <span>公司地址：</span>
            </div>
            <div class="mr">
              <img src="@/assets/images/icon_ADDRESS.png" alt="" style="width: 24px; height: 27px" />
            </div>
          </div>
          <div class="m_bIbottom">
            {{ $tools.localStorageFn.get("PAGE_DATE").address }}
          </div>
        </div>
        <div class="m_bottomItem">
          <div class="m_bItop">
            <div class="ml">
              <p>EMAIL</p>
              <span>办公邮箱：</span>
            </div>
            <div class="mr">
              <img src="@/assets/images/icon_EMAIL.png" alt="" style="width: 28px; height: 20px" />
            </div>
          </div>
          <div class="m_bIbottom">
            {{ $tools.localStorageFn.get("PAGE_DATE").email }}
          </div>
        </div>
      </div>
      <div class="m_top" @click="() => {
        toTop();
        $emit('move', 1);
      }
        ">
        <img src="@/assets/images/icon_backTop.png" alt="" />
      </div>
    </div>
    <div class="m_declaration">
      {{ $tools.localStorageFn.get("PAGE_DATE").ed }}
      <span
        @click="$tools.blankPush('/legalDeclaration')">免责声明</span><!-- 丨<span style="padding: 0" @click="goRouter">内部系统</span> -->
      <div class="declarationBox" @mouseover="diaShow = true">
        <div class="circleTip">声明</div>
        <!-- <div class="dialogueBox">
          <img src="@/assets/images/logoSmall02.png" alt="" />
          提示公众注意声明
        </div> -->
      </div>
    </div>

    <div class="dialog" :class="diaShow ? 'show' : ''" @mouseleave="diaShow = false">
      <div class="dia_header">
        <img src="@/assets/images/logoSmall02.png" alt="" />
        {{ $tools.localStorageFn.get("PAGE_DATE").prompt_title }}
      </div>
      <div class="dia_content">
        {{ $tools.localStorageFn.get("PAGE_DATE").prompt_content }}
        <!-- <div class="dia_btn" @click="$tools.blankPush('/legalDeclaration')">
          查看详情
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 弹框展示
      diaShow: false,
      menuList: [
        {
          id: "0002",
          label: "京哲介绍",
          dataList: [
            { id: "00001", title: "走进京哲", path: "/enterJingzhe" },
            { id: "00002", title: "京哲荣誉", path: "/jingzheHonor" },
            { id: "00003", title: "社会责任", path: "/jingzheWelfare" },
          ],
          path: "/enterJingzhe",
        },
        {
          id: "0003",
          label: "京哲动态",
          dataList: [
            { id: "00001", title: "京哲新闻", path: "/jingzheDynamic" },
            // { id: "00002", title: "京哲公益", path: "/jingzheWelfare" },
            { id: "00003", title: "京哲业绩", path: "/jingzhePerformance" },
            { id: "00004", title: "典型案例", path: "/typicalCase" },
          ],
          path: "/jingzheDynamic",
        },
        {
          id: "0004",
          label: "专业领域",
          dataList: [],
          path: "/areaExpertise",
        },
        {
          id: "0005",
          label: "专业人员",
          dataList: [
            { id: "00001", title: "专业人员", path: "/professionalPersonnel" },
          ],
          path: "/professionalPersonnel",
        },
        {
          id: "0006",
          label: "合作对象",
          dataList: [
            { id: "00001", title: "合作对象", path: "/cooperativePartner" },
          ],
          path: "/cooperativePartner",
        },
        {
          id: "0007",
          label: "党建工作",
          dataList: [
            { id: "00001", title: "党建工作", path: "/partyBuildingWork" },
          ],
          path: "/partyBuildingWork",
        },
        {
          id: "0008",
          label: "联系我们",
          dataList: [
            { id: "00001", title: "在线留言", path: "/onlineMessage" },
            { id: "00002", title: "联系我们", path: "/contactUs" },
            { id: "00003", title: "工作机会", path: "/jobOpportunity" },
          ],
          path: "/onlineMessage",
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData() {
      let fieldList = this.$tools.localStorageFn.get("FIELD_LIST");
      let list = this.$tools.localStorageFn.get("CATEGORIES_LIST")
      if (list) {
        list.forEach((item) => {
          if (item.id == 51) {
            this.menuList[2].label = item.title
          } else if (item.id == 44) {
            this.menuList[0].label = item.title
            this.menuList[0].dataList[0].title = item.children[0].title
            this.menuList[0].dataList[1].title = item.children[1].title
            this.menuList[0].dataList[2].title = item.children[2].title
          } else if (item.id == 47) {
            this.menuList[1].label = item.title
            this.menuList[1].dataList[0].title = item.children[0].title
            this.menuList[1].dataList[1].title = item.children[1].title
            this.menuList[1].dataList[2].title = item.children[2].title
          } else if (item.id == 52) {
            this.menuList[3].label = item.title
            this.menuList[3].dataList[0].title = item.title
          } else if (item.id == 53) {
            this.menuList[4].label = item.title
            this.menuList[4].dataList[0].title = item.children[0].title
          } else if (item.id == 55) {
            this.menuList[5].label = item.title
            this.menuList[5].dataList[0].title = item.children[0].title
          } else if (item.id == 57) {
            this.menuList[6].label = item.title
            this.menuList[6].dataList[0].title = item.children[2].title
            this.menuList[6].dataList[1].title = item.children[0].title
            this.menuList[6].dataList[2].title = item.children[1].title
          }
        })
      } else {
        this.$axios
          .get(process.env.VUE_APP_API + "/api/v1/categories")
          .then((res) => {
            console.log("所有栏目", res);
            if (res.status === 200) {
              this.$tools.localStorageFn.set("CATEGORIES_LIST", res.data);
              res.data.forEach((item) => {
                if (item.id == 51) {
                  this.menuList[2].label = item.title
                } else if (item.id == 44) {
                  this.menuList[0].label = item.title
                  this.menuList[0].dataList[0].title = item.children[0].title
                  this.menuList[0].dataList[1].title = item.children[1].title
                  this.menuList[0].dataList[2].title = item.children[2].title
                } else if (item.id == 47) {
                  this.menuList[1].label = item.title
                  this.menuList[1].dataList[0].title = item.children[0].title
                  this.menuList[1].dataList[1].title = item.children[1].title
                  this.menuList[1].dataList[2].title = item.children[2].title
                } else if (item.id == 52) {
                  this.menuList[3].label = item.title
                  this.menuList[3].dataList[0].title = item.title
                } else if (item.id == 53) {
                  this.menuList[4].label = item.title
                  this.menuList[4].dataList[0].title = item.children[0].title
                } else if (item.id == 55) {
                  this.menuList[5].label = item.title
                  this.menuList[5].dataList[0].title = item.children[0].title
                } else if (item.id == 57) {
                  this.menuList[6].label = item.title
                  this.menuList[6].dataList[0].title = item.children[2].title
                  this.menuList[6].dataList[1].title = item.children[0].title
                  this.menuList[6].dataList[2].title = item.children[1].title
                }
              })
            } else {
              this.$message.error(res.statusText);
            }
          });
      }
      this.menuList[2].dataList = [
        {
          id: fieldList[0].id,
          title: fieldList[0].name,
          path: "/areaExpertiseDetail?id=" + fieldList[0].id,
        },
        {
          id: fieldList[1].id,
          title: fieldList[1].name,
          path: "/areaExpertiseDetail?id=" + fieldList[1].id,
        },
        {
          id: "00003",
          title: "查看全部......",
          path: "/areaExpertise",
        },
      ];
    },

    goRouter() {
      window.location.href = 'http://jingzhe.ims-pro.cn/member/loginjz.aspx?ul=';
    },
    toTop() {

      let top = document.documentElement.scrollTop || document.body.scrollTop;

      // 实现滚动效果

      const timeTop = setInterval(() => {

        document.body.scrollTop =

          document.documentElement.scrollTop =

          top -= 50;

        if (top <= 0) {

          clearInterval(timeTop);

        }

      }, 10);

    },
  },
};
</script>

<style lang="scss" scoped>
.homePage08Box {
  height: 76.3rem;
  width: 100%;
  background: #0c3d6d;
  position: relative;

  .m_content {
    width: 135rem;
    padding: 6.6rem 0 6.8rem;
    margin: 0 auto;

    .m_logo {
      width: 25.6rem;
      height: 5.6rem;
      margin: 0 auto;
      margin-bottom: 3.9rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .dotted_line {
      width: 100%;

      img {
        width: 100%;
        height: 0.2rem;
      }
    }

    .m_menuBox {
      padding: 4.5rem 0 5rem;
      display: flex;

      .menuItem {
        width: calc(100% / 7);

        .menuLabel {
          cursor: pointer;
          font-size: 2rem;
          font-family: SimHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 2.4rem;

          .line {
            margin-top: 0.3rem;
            width: 0;
            height: 0.1rem;
            background: #ffffff;
            transition: 0.3s;
          }
        }

        .menuLabel:hover {
          .line {
            width: 8rem;
          }
        }

        .menuDataBox {
          margin-top: 3.2rem;

          .menuDataLabel {
            cursor: pointer;
            font-size: 1.5rem;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #ececfb;
            line-height: 1.8rem;
            margin-bottom: 2.4rem;

            >div {
              display: inline-block;
            }

            .line_2 {
              margin-top: 0.3rem;
              width: 0;
              height: 0.1rem;
              background: #ffffff;
              transition: 0.3s;
            }
          }

          .menuDataLabel:hover {
            .line_2 {
              width: 100%;
            }
          }

          .menuDataLabel:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .m_menuBottom {
      margin-top: 6.4rem;
      display: flex;
      justify-content: space-between;

      .m_bottomItem {
        .m_bItop {
          display: flex;

          .ml {
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #ebebfa;
            line-height: 2.9rem;

            p {
              margin: 0;
              font-size: 2.2rem;
            }

            span {
              font-size: 1.8rem;
            }
          }

          .mr {
            width: 4.8rem;
            height: 4.8rem;
            background: rgba(255, 255, 255, 0);
            border: 0.1rem solid #ececfb;
            border-radius: 2.4rem 2.4rem 2.4rem 2.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 4.5rem;
          }
        }

        .m_bIbottom {
          margin-top: 3.5rem;
          font-size: 1.8rem;
          font-family: SimHei;
          font-weight: 400;
          color: #ebebf9;
          line-height: 2.2rem;
        }
      }
    }
  }

  .m_declaration {
    background: #104e8b;
    height: 6.4rem;
    text-align: center;
    font-size: 1.6rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 6.4rem;

    span {
      cursor: pointer;
      padding-left: 2.4rem;
    }

    .declarationBox {
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      left: 14rem;
      top: 41rem;

      .circleTip {
        width: 3.9rem;
        height: 3.9rem;
        background: #0c3d6d;
        border: 0.3rem #fff solid;
        border-radius: 50%;
        font-size: 1.1rem;
        font-family: SimHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 3.3rem;
      }

      .dialogueBox {
        position: relative;
        margin-left: 2rem;
        height: 4.3rem;
        background: #0c3d6d;
        padding: 0 2rem;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-family: SimHei;
        font-weight: 400;
        color: #ffffff;

        img {
          width: 2.6rem;
          height: 1.4rem;
          margin-right: 1.3rem;
        }
      }

      .dialogueBox::before {
        content: "";
        position: absolute;
        border-top: 1.3rem solid transparent;
        border-bottom: 1.3rem solid transparent;
        border-right: 1.3rem solid #0c3d6d;
        width: 0;
        height: 0;
        left: -1.3rem;
      }
    }
  }

  .m_top {
    cursor: pointer;
    width: 3.3rem;
    height: 3.3rem;
    position: absolute;
    right: 60px;
    bottom: 11rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .dialog {
    width: 26.3rem;
    position: absolute;
    left: 22rem;
    bottom: 18rem;
    transform: translateX(-60rem);
    transition: 0.6s;

    .dia_header {
      height: 4.5rem;
      background: #104e8b;
      padding: 0 2.3rem;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-family: SimHei;
      font-weight: 400;
      color: #ffffff;

      img {
        width: 2.6rem;
        height: 1.4rem;
        margin-right: 1.3rem;
      }
    }

    .dia_content {
      background: #ffffff;
      padding: 3.3rem 2rem;
      font-size: 1.5rem;
      font-family: SimHei;
      font-weight: 400;
      color: #0c3d6c;
      line-height: 2.4rem;

      .dia_btn {
        cursor: pointer;
        margin: 4.3rem auto 0;
        width: 12rem;
        height: 3.1rem;
        background: rgba(12, 61, 108, 0);
        border: 0.1rem solid #0c3d6c;
        border-radius: 1.6rem 1.6rem 1.6rem 1.6rem;
        font-size: 1.4rem;
        font-family: SimHei;
        font-weight: 400;
        color: #0c3d6c;
        line-height: 3.1rem;
        text-align: center;
      }
    }
  }

  .show {
    transform: translateX(0) !important;
  }

  @media screen and (min-width: 1920px) {
    height: 763px;

    .m_content {
      width: 1400px;
      padding: 66px 0 68px;

      .m_logo {
        width: 256px;
        height: 56px;
        margin-bottom: 39px;
      }

      .dotted_line {
        img {
          height: 02px;
        }
      }

      .m_menuBox {
        padding: 45px 0 50px;

        .menuItem {
          .menuLabel {
            font-size: 20px;
            line-height: 24px;

            .line {
              margin-top: 3px;
              height: 1px;
            }
          }

          .menuLabel:hover {
            .line {
              width: 80px;
            }
          }

          .menuDataBox {
            margin-top: 32px;

            .menuDataLabel {
              font-size: 15px;
              line-height: 18px;
              margin-bottom: 24px;

              .line_2 {
                margin-top: 3px;
                height: 1px;
              }
            }
          }
        }
      }

      .m_menuBottom {
        margin-top: 64px;

        .m_bottomItem {
          .m_bItop {
            .ml {
              line-height: 29px;

              p {
                font-size: 22px;
              }

              span {
                font-size: 18px;
              }
            }

            .mr {
              width: 48px;
              height: 48px;
              border: 1px solid #ececfb;
              border-radius: 24px 24px 24px 24px;
              margin-left: 45px;
            }
          }

          .m_bIbottom {
            margin-top: 35px;
            font-size: 18px;
            line-height: 22px;
          }
        }
      }
    }

    .m_declaration {
      height: 64px;
      font-size: 16px;
      font-weight: 400;
      line-height: 64px;

      span {
        padding-left: 24px;
      }

      .declarationBox {
        left: 140px;
        top: 410px;

        .circleTip {
          width: 39px;
          height: 39px;
          border: 3px #fff solid;
          font-size: 11px;
          line-height: 33px;
        }

        .dialogueBox {
          margin-left: 20px;
          height: 43px;
          padding: 0 20px;
          font-size: 14px;

          img {
            width: 26px;
            height: 14px;
            margin-right: 13px;
          }
        }

        .dialogueBox::before {
          border-top: 13px solid transparent;
          border-bottom: 13px solid transparent;
          border-right: 13px solid #0c3d6d;
          left: -13px;
        }
      }
    }

    .m_top {
      width: 33px;
      height: 33px;
      right: 60px;
      bottom: 110px;
    }

    .dialog {
      width: 263px;
      left: 220px;
      bottom: 180px;
      transform: translateX(-600px);

      .dia_header {
        height: 45px;
        padding: 0 23px;
        font-size: 14px;

        img {
          width: 26px;
          height: 14px;
          margin-right: 13px;
        }
      }

      .dia_content {
        padding: 33px 20px;
        font-size: 15px;
        line-height: 24px;

        .dia_btn {
          margin: 43px auto 0;
          width: 120px;
          height: 31px;
          border: 1px solid #0c3d6c;
          border-radius: 16px 16px 16px 16px;
          font-size: 14px;
          line-height: 31px;
        }
      }
    }
  }
}
</style>