<template>
  <div class="myMenuBox">
    <div class="menuBox">
      <div class="m_header">
        <div class="ml_logo" @click="jumpRouter('/')">
          <img src="@/assets/images/logo01.png" alt="" />
        </div>
        <div class="ml_close">
          <i class="el-icon-close pointer" @click="$emit('closeNav')"></i>
        </div>
      </div>
      <div class="m_menuList">
        <div class="m_item" v-for="item in menuList" :key="item.id">
          <div class="m_label">
            <span class="pointer" @click="jumpRouter(item.path)">
              {{ item.label }}</span>
            <!-- <span class="add" v-if="item.dataList.length > 0">+</span> -->
          </div>
          <div class="m_listBox" v-if="item.dataList.length > 0">
            <div class="m_Listarea">
              <div class="m_ListItem pointer" v-for="ele in item.dataList" :key="ele.id" @click="jumpRouter(ele.path)">
                <span> {{ ele.title }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m_footer">
        <a href="http://jingzhe.ims-pro.cn/member/loginjz.aspx?ul=" target="_blank" style="margin-right: 6px;">内部系统</a> 丨
        <a href="https://exmail.qq.com/login" target="_blank" style="margin-left: 6px;">企业邮箱</a>
      </div>
    </div>
    <div class="menuBg" @click="$emit('closeNav')">
      <img :src="$tools.localStorageFn.get('PAGE_DATE').menu_bg_r" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        { id: "0001", label: "首页", dataList: [], path: "/" },
        {
          id: "0004",
          label: "专业领域",
          path: "/areaExpertise",
          dataList: [],
        },
        {
          id: "0002",
          label: "京哲介绍",
          dataList: [
            { id: "00001", title: "走进京哲", path: "/enterJingzhe" },
            { id: "00002", title: "京哲荣誉", path: "/jingzheHonor" },
            { id: "00003", title: "社会责任", path: "/jingzheWelfare" },
          ],
          path: "/enterJingzhe",
        },
        {
          id: "0003",
          label: "京哲动态",
          dataList: [
            { id: "00001", title: "京哲新闻", path: "/jingzheDynamic" },
            // { id: "00002", title: "京哲公益", path: "/jingzheWelfare" },
            { id: "00003", title: "京哲业绩", path: "/jingzhePerformance" },
            { id: "00004", title: "典型案例", path: "/typicalCase" },
          ],
          path: "/jingzheDynamic",
        },
        {
          id: "0005",
          label: "专业团队",
          dataList: [],
          path: "/professionalPersonnel",
        },
        {
          id: "0006",
          label: "合作对象",
          dataList: [
            { id: "00001", title: "合作对象", path: "/cooperativePartner" },
          ],
          path: "/cooperativePartner",
        },
        {
          id: "0007",
          label: "党建工作",
          dataList: [
            { id: "00001", title: "党建工作", path: "/partyBuildingWork" },
          ],
          path: "/partyBuildingWork",
        },
        {
          id: "0008",
          label: "联系我们",
          dataList: [
            { id: "00001", title: "在线留言", path: "/onlineMessage" },
            { id: "00002", title: "联系我们", path: "/contactUs" },
            { id: "00003", title: "工作机会", path: "/jobOpportunity" },
          ],
          path: "/onlineMessage",
        },
      ],
    };
  },
  created() {
    this.initData();
  },
  methods: {
    // 初始化数据
    initData() {
      let list = this.$tools.localStorageFn.get("CATEGORIES_LIST")
      if (list) {
        list.forEach((item) => {
          if (item.id == 67) {
            this.menuList[0].label = item.title
          } else if (item.id == 51) {
            this.menuList[1].label = item.title
          } else if (item.id == 44) {
            this.menuList[2].label = item.title
            this.menuList[2].dataList[0].title = item.children[0].title
            this.menuList[2].dataList[1].title = item.children[1].title
            this.menuList[2].dataList[2].title = item.children[2].title
          } else if (item.id == 47) {
            this.menuList[3].label = item.title
            this.menuList[3].dataList[0].title = item.children[0].title
            this.menuList[3].dataList[1].title = item.children[1].title
            this.menuList[3].dataList[2].title = item.children[2].title
          } else if (item.id == 52) {
            this.menuList[4].label = item.title
          } else if (item.id == 53) {
            this.menuList[5].label = item.title
            this.menuList[5].dataList[0].title = item.children[0].title
          } else if (item.id == 55) {
            this.menuList[6].label = item.title
            this.menuList[6].dataList[0].title = item.children[0].title
          } else if (item.id == 57) {
            this.menuList[7].label = item.title
            this.menuList[7].dataList[0].title = item.children[0].title
            this.menuList[7].dataList[1].title = item.children[1].title
            this.menuList[7].dataList[2].title = item.children[2].title
          }
        })
      } else {
        this.$axios
          .get(process.env.VUE_APP_API + "/api/v1/categories")
          .then((res) => {
            console.log("所有栏目", res);
            if (res.status === 200) {
              this.$tools.localStorageFn.set("CATEGORIES_LIST", res.data);
              res.data.forEach((item) => {
                if (item.id == 67) {
                  this.menuList[0].label = item.title
                } else if (item.id == 51) {
                  this.menuList[1].label = item.title
                } else if (item.id == 44) {
                  this.menuList[2].label = item.title
                  this.menuList[2].dataList[0].title = item.children[0].title
                  this.menuList[2].dataList[1].title = item.children[1].title
                  this.menuList[2].dataList[2].title = item.children[2].title
                } else if (item.id == 47) {
                  this.menuList[3].label = item.title
                  this.menuList[3].dataList[0].title = item.children[0].title
                  this.menuList[3].dataList[1].title = item.children[1].title
                  this.menuList[3].dataList[2].title = item.children[2].title
                } else if (item.id == 52) {
                  this.menuList[4].label = item.title
                } else if (item.id == 53) {
                  this.menuList[5].label = item.title
                  this.menuList[5].dataList[0].title = item.children[0].title
                } else if (item.id == 55) {
                  this.menuList[6].label = item.title
                  this.menuList[6].dataList[0].title = item.children[0].title
                } else if (item.id == 57) {
                  this.menuList[7].label = item.title
                  this.menuList[7].dataList[0].title = item.children[0].title
                  this.menuList[7].dataList[1].title = item.children[1].title
                  this.menuList[7].dataList[2].title = item.children[2].title
                }
              })
            } else {
              this.$message.error(res.statusText);
            }
          });
      }
      this.$tools.localStorageFn.get("FIELD_LIST").forEach((item) => {
        this.menuList[1].dataList.push({
          id: item.id,
          title: item.name,
          path: "/areaExpertiseDetail?id=" + item.id,
        });
      });
    },
    //路由跳转
    jumpRouter(route) {
      this.$emit("closeNav");
      if (this.$route.path == route) {
        this.$router.go(0);
      } else {
        this.$tools.blankPush(route);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.myMenuBox {
  .menuBox {
    overflow: hidden;
    transition: 0.6s;
    background: rgb(255 255 255 / 75%);
    position: fixed;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    z-index: 210;

    .m_header {
      opacity: 0;
      transition: 0.6s;
      padding: 4.8rem 8.3rem 0 5rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 8.1rem;

      .ml_logo {
        cursor: pointer;

        img {
          width: 19.2rem;
          height: 4.2rem;
        }
      }

      .ml_close {
        position: relative;

        i {
          font-size: 2.4rem;
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }

    .m_menuList {
      opacity: 0;
      transition: 0.6s;

      .m_item {
        padding: 0 8.6rem 0 5rem;
        position: relative;

        .m_label {
          white-space: nowrap;
          position: relative;
          padding: 1.9rem 0;
          font-size: 3.5rem;
          font-family: JingZhe_XiaoWei;
          font-weight: 400;
          color: #333;
          line-height: 3.8rem;
          // display: flex;
          // justify-content: right;
          text-align: right;
          transition: 0.6s;
          position: relative;
          height: 3.8rem;
          box-sizing: content-box;

          .add {
            visibility: hidden;
          }

          span {
            position: absolute;
            right: 0;
            display: block;
            width: 15rem;
            text-align: center;
          }
        }

        // .m_label::after {
        //   transition: 0.6s;
        //   content: "";
        //   display: block;
        //   width: 0;
        //   height: 0.2rem;
        //   background: #063363;
        //   position: absolute;
        //   bottom: 0;
        //   left: 0;
        // }
        .m_listBox {
          overflow: hidden;
          visibility: hidden;
          position: absolute;
          top: 0;
          left: 49.7rem;
          padding-left: 4.1rem;

          .m_Listarea {
            padding: 5.7rem 7.2rem 6.1rem 8.1rem;
            background: rgb(255 255 255 / 75%);
            transition: 0.6s;
            opacity: 0;
            width: 0;
            position: relative;
            display: flex;
            flex-wrap: wrap;

            .m_ListItem {
              width: (100% / 3);
              font-size: 2.2rem;
              font-family: JingZhe_XiaoWei;
              font-weight: 400;
              color: #333;
              line-height: 2.4rem;
              height: 2.4rem;
              white-space: nowrap;
              margin-top: 3.6rem;
              transition: 0.4s;
            }

            .m_ListItem:nth-child(-n + 3) {
              margin-top: 0;
            }

            .m_ListItem:hover {
              font-size: 2.4rem;
              color: #063363;
            }
          }

          .m_Listarea::before {
            position: absolute;
            content: "";
            border: solid transparent;
            border-top-width: 1.4rem;
            border-bottom-width: 1.4rem;
            border-left-width: 2rem;
            border-right-width: 2rem;
            border-right-color: rgb(255 255 255 / 75%);
            top: 2.8rem;
            left: -4rem;
          }
        }
      }

      .m_item:first-child {
        .m_label {
          span {
            width: 7.5rem;
          }
        }
      }

      .m_item:hover {
        .m_listBox {
          visibility: initial;
        }

        .m_Listarea {
          opacity: 1;
          width: 98rem;
        }

        .m_label {
          color: #063363;
          font-size: 3.8rem;
        }

        // .m_label::after {
        //   width: 36rem;
        // }
        .m_label .add {
          visibility: initial;
        }
      }
    }

    .m_footer {
      cursor: pointer;
      width: 100%;
      position: absolute;
      bottom: 4.8rem;
      display: flex;
      padding-left: 5rem;
      font-size: 2.4rem;

      font-family: JingZhe_XiaoWei;

      a {
        white-space: nowrap;
        color: #333;
        transition: .3s;
      }

      a:hover {
        color: #013060;
      }
    }
  }

  .menuBg {
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    // display: none;
    background: #013060;
    opacity: 0;

    img {
      width: 100%;
      height: 100%;
      display: none;
    }
  }

  @media screen and (min-width: 1920px) {
    .menuBox {
      .m_header {
        padding: 48px 83px 0 50px;
        margin-bottom: 81px;

        .ml_logo {
          img {
            width: 192px;
            height: 42px;
          }
        }

        .ml_close {
          i {
            font-size: 24px;
          }
        }
      }

      .m_menuList {
        .m_item {
          padding: 0 86px 0 50px;

          .m_label {
            padding: 19px 0;
            font-size: 35px;
            line-height: 38px;

            span {
              width: 150px;
            }
          }

          // .m_label::after {
          //   height: 2px;
          // }
          .m_listBox {
            left: 497px;
            padding-left: 41px;

            .m_Listarea {
              padding: 57px 72px 61px 81px;

              .m_ListItem {
                font-size: 22px;
                line-height: 24px;
                height: 24px;
                margin-top: 36px;
              }

              .m_ListItem:hover {
                font-size: 24px;
              }
            }

            .m_Listarea::before {
              border-top-width: 14px;
              border-bottom-width: 14px;
              border-left-width: 20px;
              border-right-width: 20px;
              top: 28px;
              left: -40px;
            }
          }
        }

        .m_item:first-child {
          .m_label {
            span {
              width: 7.5rem;
            }
          }
        }

        .m_item:hover {
          .m_label {
            font-size: 38px;
          }

          .m_Listarea {
            width: 980px;
          }

          // .m_label::after {
          //   width: 360px;
          // }
        }
      }


      .m_footer {
        padding-left: 50px;
        bottom: 64px;
        font-size: 24px;
      }
    }
  }
}

.on {
  .menuBox {
    overflow: initial;
    width: 49.6rem;

    .m_header {
      opacity: 1;
    }

    .m_menuList {
      opacity: 1;
    }
  }

  .menuBg {
    display: block;
    position: fixed;
    z-index: 205;
    width: 100%;
    opacity: 1;

    img {
      display: inline;
    }
  }

  @media screen and (min-width: 1920px) {
    .menuBox {
      width: 496px;
    }
  }
}
</style>