<template>
  <div class="myHeaderBox">
    <div class="myNav">
      <div class="myLogo" @click="() => {
        $router.push('/');
      }
        ">
        <img src="@/assets/images/logo02.png" alt="" />
      </div>
      <div class="mN_right">
        <div class="mN_lag">
          <div class="mN_lag pointer">
            <div class="text">CN</div>
          </div>
        </div>
        <div class="mN_icons">
          <div class="mN_icon icon_search02" @click="phSearchShow = !phSearchShow"></div>
          <div class="mN_icon icon_menu02" @click="phNavShow = !phNavShow"></div>
        </div>
      </div>
    </div>
    <div style="height: 6.4rem;"></div>
    <!-- <div style="height: 6.4rem"></div> -->
    <div class="mh_img" v-if="show" :style="'background: url(' + imgBg + ') no-repeat center center'">
      <!-- <video class="video-background" preload="auto" loop playsinline autoplay :src="videoSrc" tabindex="-1"
        muted="muted"></video> -->
      <div class="mh_title">
        <div class="label">{{ title }}</div>
        <!-- <div class="line"></div> -->
      </div>
    </div>

    <!-- 移动端导航 -->
    <van-popup v-model="phNavShow" position="right" style="z-index: 2000" :style="{ height: '100%', width: '100%' }">
      <div class="phNav">
        <div class="pn_top">
          <div class="pnh_tr">
            <div class="pnh_search01"></div>
            <input type="text" placeholder="搜索" name="" id="" v-model="searchText" />
            <div class="pnh_rightArrow" @click="search(2)" v-loading="loading"></div>
          </div>
          <div class="pnh_tl">
            <div class="ptl_text">
              <div class="mN_lag pointer">
                <div class="text">CN</div>
              </div>
            </div>
            <div class="pnh_close" @click="() => {
              phNavShow = false;
            }
              "></div>
          </div>
        </div>
        <div class="pn_area">
          <phoneMenu></phoneMenu>
        </div>
        <div class="pn_footer">
          <a href="http://jingzhe.ims-pro.cn/member/loginjz.aspx?ul=" style="margin-right: .6rem;">内部系统</a>丨
          <a href="https://exmail.qq.com/login" style="margin-left: .4rem;">企业邮箱</a>
        </div>
      </div>
    </van-popup>

    <!-- 移动端搜索栏 -->
    <van-popup v-model="phSearchShow" position="top" :style="{ height: '60%', width: '100%' }" style="z-index: 2000">
      <div class="ph_search">
        <div class="ps_top">
          <div></div>
          <div class="ps_logo"></div>
          <div class="ps_close">
            <div class="icon_close" @click="() => {
              phSearchShow = false;
            }
              "></div>
          </div>
        </div>
        <div class="ps_mid">
          <div class="ps_title">探索京哲</div>
          <div class="ps_inputArea">
            <input type="text" placeholder="搜索" v-model="searchText" />
            <div class="ps_rightArrow" @click="search(2)" v-loading="loading">
              <div class="ps_icon"></div>
            </div>
          </div>
          <div class="ps_label">京哲智慧，开启未来</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["imgShow"],
  data() {
    return {
      title: "",
      show: true,
      // 移动端
      phNavShow: false, //控制导航栏显示
      phSearchShow: false, //移动端搜索
      activeNames: "", //默认展开页
      // 业务领域
      options: [
        {
          value: "1",
          label: "公司法律事务",
        },
        {
          value: "2",
          label: "民事争议调解与诉讼",
        },
        {
          value: "3",
          label: "刑事辩护与风险防范",
        },
        {
          value: "4",
          label: "商事争端与仲裁",
        },
        {
          value: "5",
          label: "医药健康法律",
        },
        {
          value: "6",
          label: "房地产与建筑工程",
        },
        {
          value: "7",
          label: "债务重组与特殊资产处置",
        },
        {
          value: "8",
          label: "行政法律事务",
        },
        {
          value: "9",
          label: "涉外投融资与国际贸易",
        },
        {
          value: "10",
          label: "环境保护与矿产资源开发",
        },
        {
          value: "11",
          label: "知识产权",
        },
        {
          value: "12",
          label: "政府法律事务",
        },
        {
          value: "13",
          label: "劳动与人力资源",
        },
        {
          value: "14",
          label: "企业与家族财富传承",
        },
        {
          value: "15",
          label: "娱乐与传媒",
        },
        {
          value: "16",
          label: "公益法律服务中心",
        },
        {
          value: "17",
          label: "项目投资与并购",
        },
        {
          value: "18",
          label: "金融私募与风险投资",
        },
        {
          value: "19",
          label: "汽车",
        },
        {
          value: "20",
          label: "民事交叉业务",
        },
      ],
      value: "",

      imgBg: "",
      videoSrc: "",

      searchText: '', loading: false,
    };
  },
  created() {
    if (this.imgShow == false) {
      this.show = this.imgShow;
    } else {
      this.show = true;
    }
  },
  activated() {
    this.phNavShow = false
  },
  methods: {
    jumpRouter(route) {
      if (this.$route.path == route) {
        this.$router.go(0);
      } else {
        this.$router.push(route);
      }
    },


    search(s) {
      if (this.searchText == '') {
        this.$message.warning('请输入搜索内容!')
        return
      } else {
        this.loading = true
        this.$axios.get(process.env.VUE_APP_API + '/api/v1/articles', {
          params: {
            filter: { title: this.searchText }
          }
        }).then((res) => {
          console.log('搜索结果', res)
          if (res.status == 200) {
            if (res.data.data.length == 0) {
              this.$message.warning('未查询任何数据!')
              return
            }
            this.searchShow = false
            if (s == 1) {
              this.$router.push('/jingzheDynamic?q=' + this.searchText)
            } else { this.$router.push('/mobileJingzheDynamic?q=' + this.searchText) }
          } else {
            this.$message.error(res.message);
          }
        }).finally(() => {
          this.loading = false;
        });

      }
    },

    close() {
      this.phNavShow = false
    }
  },
};
</script>

<style lang="scss" scoped>
.myHeaderBox {
  position: relative;

  .myNav {
    position: fixed;
    // background: #;
    background: #0c3d6d;
    // position: absolute;
    top: 0;
    left: 0;
    padding: 2.3rem;
    width: 100%;
    height: 6.4rem;
    // background: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2000;

    .myLogo {
      width: 11.1rem;
      height: 2.5rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .mN_right {
      display: flex;
      column-gap: 1.7rem;

      .mN_lag {
        background: url(~@/assets/images/icon_language.png) no-repeat center center;
        background-size: 100% 100%;
        width: 1.6rem;
        height: 1.6rem;
        // margin-right: 5..2rem;
        font-size: .2rem;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #fff;
        position: relative;

        .text {
          position: absolute;
          right: 0;
          bottom: 0;
          transform: translate(50%, 50%);
          font-size: .8rem;
          font-weight: 600;
        }
      }

      .mN_icons {
        display: flex;
        column-gap: 1.5rem;

        .mN_icon {
          width: 1.6rem;
          height: 1.6rem;
        }

        .icon_search02 {
          background: url(~@/assets/images/icon_search01.png) no-repeat center center;
          background-size: 100% 100%;
        }

        .icon_menu02 {
          background: url(~@/assets/images/icon_menu.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
  }

  .mh_img {
    width: 100%;
    height: 16.4rem;

    background: url(~@/assets/images/myNavBg.png) no-repeat center center;
    background-size: cover;
    position: relative;

    video {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }

    .mh_title {
      position: absolute;
      animation: tilt-in-left-_8 1s ease 0s 1 normal none;
      transition: 0.6s all;
      top: 6.9rem;
      left: 2.3rem;
      // width: 17.6rem;
      height: 7.6rem;
      // background: #00305f;
      // opacity: 0.8;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 1.1rem;
      justify-content: center;

      .label {
        font-size: 3.2rem;
        font-family: JingZhe_XiaoWei;
        font-weight: 500;
        color: #ffffff;
        line-height: 3.0rem;
      }

      .line {
        width: 7.0rem;
        height: .2rem;
        background: #ffffff;
      }
    }
  }

  ::v-deep .van-overlay {
    z-index: 1 !important;
  }

  // 导航
  ::v-deep .phNav {
    position: relative;
    min-height: 100%;
    width: 100%;

    .pn_top {
      padding: 1.2rem 2.3rem;
      background: #013060;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pnh_tr {
        display: flex;
        padding: 0 .9rem;
        align-items: center;
        background: rgba(64, 64, 81, 0);
        border: .1rem solid #ffffff;

        .pnh_search01 {
          width: 1.5rem;
          height: 1.5rem;
          background: url(~@/assets/images/icon_search01.png) no-repeat center center;
          background-size: 100% 100%;
          margin-right: .7rem;
        }

        input {
          width: 13.3rem;
          background: transparent;
          font-size: 1.2rem;
          font-family: SimHei;
          font-weight: 400;
          color: #ffffff;
          line-height: 2.9rem;
          border: 0;
        }

        input::placeholder {
          color: #ffffff;
        }

        .pnh_rightArrow {
          width: 1.7rem;
          height: .8rem;
          background: url(~@/assets/images/icon_rightArrow.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }

      .pnh_tl {
        display: flex;
        align-items: center;

        .ptl_text {
          margin: 0;
          padding: 0;
          font-size: 1.3rem;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #ffffff;
          line-height: 1.5rem;

          .mN_lag {
            background: url(~@/assets/images/icon_language.png) no-repeat center center;
            background-size: 100% 100%;
            width: 1.6rem;
            height: 1.6rem;
            // margin-right: 5..2rem;
            font-size: .2rem;
            font-family: Microsoft YaHei UI;
            font-weight: 400;
            color: #fff;
            position: relative;

            .text {
              position: absolute;
              right: 0;
              bottom: 0;
              transform: translate(50%, 50%);
              font-size: .8rem;
              font-weight: 600;
            }
          }
        }

        .pnh_close {
          margin-left: 2.6rem;
          width: 1.2rem;
          height: 1.2rem;
          background: url(~@/assets/images/icon_close.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }

    .pn_area {
      padding: 2.0rem 2.3rem;
      padding-bottom: 8.8rem;

      .pn_icons {
        display: flex;
        align-items: center;
      }

      .pn_plus {
        background: url(~@/assets/images/icon_plus.png) no-repeat center center;
        background-size: 100% 100%;
        width: 1.2rem;
        height: 1.2rem;
      }

      .pm_inputArea {
        // transform: translateY(6.0rem);
        // opacity: 0;
        // transition: 1s 0.6s;
        display: flex;
        flex-direction: column;
        row-gap: 1.3rem;
        padding: 1.2rem 0;

        .pm_inputItem {
          display: flex;
          align-items: center;
          column-gap: .8rem;

          .btn {
            display: inline-block;
            width: 16%;
            height: 3.4rem;
            background: #033061;
            color: #fff;
            font-size: 1.2rem;
            line-height: 3.4rem;
            text-align: center;
          }
        }

        input {
          height: 3.4rem;
          box-sizing: border-box;
          width: 80%;
          border: .1rem solid #033061;
          // margin-bottom: 1.3rem;
          padding: 1.1rem;
        }

        input::placeholder {
          font-size: 1.2rem;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #023060;
          line-height: 1.4rem;
        }

        .pm_select {
          box-sizing: border-box;
          width: 100%;
          border: .1rem solid #033061;
          padding: 1.1rem 1.7rem 1.1rem 1.1rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 1.2rem;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #023060;
          line-height: 1.4rem;
          margin-bottom: 1.9rem;
        }

        .el-select {
          width: 80%;

          .el-input {
            border: .1rem solid #033061;

            input {
              height: 3.2rem;
              background: rgba(68, 68, 68, 0);
              margin: 0;
              // height: 52px;
              // line-height: 52px;
              border-radius: 0;
              border: none;
              padding: 1.1rem;
              font-size: 1.2rem;
            }

            input::placeholder {
              color: #033061;
              // font-size: 16px;
            }

            .el-input__suffix {
              right: 1.2rem;

              .el-input__icon {
                font-size: 1.2rem;
                line-height: 3.4rem;
                color: #093665;
              }
            }
          }
        }
      }

      .van-hairline--top-bottom::after {
        border: 0;
      }

      .van-collapse-item {
        border-bottom: .1rem solid #013060;

        .van-cell {
          padding: 1.7rem .2rem;

          .van-cell__title {
            font-size: 2.2rem;
            font-family: JingZhe_XiaoWei;
            font-weight: 400;
            color: #013060;
            line-height: 2.2rem;
          }
        }

        .van-cell::after {
          border: 0;
        }

        .van-collapse-item__content {
          padding: .3rem .4rem;

          ul {
            li {
              font-size: 1.8rem;
              font-family: JingZhe_XiaoWei;
              font-weight: 400;
              color: #3f3f51;
              line-height: 1.8rem;
              margin-bottom: 1.5rem;
            }
          }
        }
      }

      .van-collapse-item:first-child {
        .van-cell::after {
          border: none;
        }
      }

      .van-collapse-item__title--expanded {
        .pn_plus {
          background: url(~@/assets/images/icon_minus.png) no-repeat center center;
          background-size: 100% 100%;
          width: 1.2rem;
          height: .2rem;
        }
      }
    }

    .pn_footer {
      position: absolute;
      font-size: 1.8rem;
      left: 2.3rem;
      bottom: 4.8rem;
      font-family: JingZhe_XiaoWei;

      a {
        color: #013060;
      }
    }
  }

  //搜索
  .ph_search {
    width: 100%;
    height: 100%;
    padding: 2.9rem 2.3rem 0 4.0rem;
    background: #f8f8f8;

    .ps_top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5.6rem;

      .ps_logo {
        width: 15.0rem;
        height: 3.3rem;
        background: url(~@/assets/images/logo01.png) no-repeat center center;
        background-size: 100% 100%;
      }

      .ps_close {
        width: 2.5rem;
        height: 2.5rem;
        background: #0e3a68;
        border-radius: 1.2rem;
        display: flex;
        align-items: center;

        .icon_close {
          margin: auto;
          width: .9rem;
          height: .9rem;
          background: url(~@/assets/images/icon_close.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }

    .ps_mid {
      .ps_title {
        text-align: center;
        font-size: 1.5rem;
        font-family: Microsoft YaHei UI;
        font-weight: bold;
        color: #202020;
        line-height: 1.8rem;
        margin-bottom: 4.2rem;
      }

      .ps_inputArea {
        display: flex;
        align-items: center;
        margin-bottom: 1.4rem;

        input {
          width: 28.1rem;
          font-size: 1.2rem;
          font-family: Microsoft YaHei UI;
          font-weight: 400;
          color: #868686;
          line-height: 4.0rem;
          border: 0;
          border-bottom: .1rem solid #212121;
          background: transparent;
        }

        input::after {
          color: #868686;
        }

        .ps_rightArrow {
          width: 2.3rem;
          height: 2.3rem;
          background: #0e3a68;
          border-radius: 1.2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 1.3rem;

          .ps_icon {
            width: 1.2rem;
            height: .9rem;
            background: url(~@/assets/images/icon_rightArrow.png) no-repeat center center;
            background-size: 100% 100%;
          }
        }
      }

      .ps_label {
        font-size: 1.2rem;
        font-family: Microsoft YaHei UI;
        font-weight: 400;
        color: #0e3a68;
        line-height: 1.4rem;
      }
    }
  }
}
</style>