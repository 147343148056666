<template>
    <van-collapse v-model="activeNames" accordion>
        <van-collapse-item :name="item.id" title="" v-for="item in menuList" :key="item.id">
            <template #title>
                <span @click="jumpRouter(item.path)">{{ item.label }}</span>
            </template>
            <template #right-icon>
                <div class="pn_icons">
                    <div class="pn_plus"></div>
                </div>
            </template>
            <ul v-if="item.dataList.length > 0">
                <li v-for="ele in item.dataList" :key="ele.id" @click="jumpRouter(ele.path)">{{ ele.title }}</li>
            </ul>
        </van-collapse-item>

    </van-collapse>
</template>

<script>
export default {
    data() {
        return {
            menuList: [
                { id: "0001", label: "首页", dataList: [], path: "/" },
                {
                    id: "0004",
                    label: "专业领域",
                    path: "/mobileareaExpertise",
                    dataList: [],
                },
                {
                    id: "0002",
                    label: "京哲介绍",
                    dataList: [
                        { id: "00001", title: "走进京哲", path: "/mobileenterJingzhe" },
                        { id: "00002", title: "京哲荣誉", path: "/mobilejingzheHonor" },
                        { id: "00003", title: "社会责任", path: "/mobilejingzheWelfare" },
                    ],
                    path: "/mobileenterJingzhe",
                },
                {
                    id: "0003",
                    label: "京哲动态",
                    dataList: [
                        { id: "00001", title: "京哲新闻", path: "/mobilejingzheDynamic" },
                        // { id: "00002", title: "京哲公益", path: "/jingzheWelfare" },
                        { id: "00003", title: "京哲业绩", path: "/mobilejingzhePerformance" },
                        { id: "00004", title: "典型案例", path: "/mobiletypicalCase" },
                    ],
                    path: "/mobilejingzheDynamic",
                },
                {
                    id: "0005",
                    label: "专业团队",
                    dataList: [],
                    path: "/mobileprofessionalPersonnel",
                },
                {
                    id: "0006",
                    label: "合作对象",
                    dataList: [
                        { id: "00001", title: "合作对象", path: "/mobilecooperativePartner" },
                    ],
                    path: "/mobilecooperativePartner",
                },
                {
                    id: "0007",
                    label: "党建工作",
                    dataList: [
                        { id: "00001", title: "党建工作", path: "/mobilepartyBuildingWork" },
                    ],
                    path: "/mobilepartyBuildingWork",
                },
                {
                    id: "0008",
                    label: "联系我们",
                    dataList: [
                        { id: "00001", title: "在线留言", path: "/mobileonlineMessage" },
                        { id: "00002", title: "联系我们", path: "/mobilecontactUs" },
                        { id: "00003", title: "工作机会", path: "/mobilejobOpportunity" },
                    ],
                    path: "/mobileonlineMessage",
                },
            ],

            activeNames: "", //默认展开页
        }
    },
    created() {
        this.initData();
    },
    methods: {
        // 初始化数据
        initData() {
            let list = this.$tools.localStorageFn.get("CATEGORIES_LIST")
            if (list) {
                list.forEach((item) => {
                    if (item.id == 67) {
                        this.menuList[0].label = item.title
                    } else if (item.id == 51) {
                        this.menuList[1].label = item.title
                    } else if (item.id == 44) {
                        this.menuList[2].label = item.title
                        this.menuList[2].dataList[0].title = item.children[0].title
                        this.menuList[2].dataList[1].title = item.children[1].title
                        this.menuList[2].dataList[2].title = item.children[2].title
                    } else if (item.id == 47) {
                        this.menuList[3].label = item.title
                        this.menuList[3].dataList[0].title = item.children[0].title
                        this.menuList[3].dataList[1].title = item.children[1].title
                        this.menuList[3].dataList[2].title = item.children[2].title
                    } else if (item.id == 52) {
                        this.menuList[4].label = item.title
                    } else if (item.id == 53) {
                        this.menuList[5].label = item.title
                        this.menuList[5].dataList[0].title = item.children[0].title
                    } else if (item.id == 55) {
                        this.menuList[6].label = item.title
                        this.menuList[6].dataList[0].title = item.children[0].title
                    } else if (item.id == 57) {
                        this.menuList[7].label = item.title
                        this.menuList[7].dataList[0].title = item.children[0].title
                        this.menuList[7].dataList[1].title = item.children[1].title
                        this.menuList[7].dataList[2].title = item.children[2].title
                    }
                })
            } else {
                this.$axios
                    .get(process.env.VUE_APP_API + "/api/v1/categories")
                    .then((res) => {
                        console.log("所有栏目", res);
                        if (res.status === 200) {
                            this.$tools.localStorageFn.set("CATEGORIES_LIST", res.data);
                            res.data.forEach((item) => {
                                if (item.id == 67) {
                                    this.menuList[0].label = item.title
                                } else if (item.id == 51) {
                                    this.menuList[1].label = item.title
                                } else if (item.id == 44) {
                                    this.menuList[2].label = item.title
                                    this.menuList[2].dataList[0].title = item.children[0].title
                                    this.menuList[2].dataList[1].title = item.children[1].title
                                    this.menuList[2].dataList[2].title = item.children[2].title
                                } else if (item.id == 47) {
                                    this.menuList[3].label = item.title
                                    this.menuList[3].dataList[0].title = item.children[0].title
                                    this.menuList[3].dataList[1].title = item.children[1].title
                                    this.menuList[3].dataList[2].title = item.children[2].title
                                } else if (item.id == 52) {
                                    this.menuList[4].label = item.title
                                } else if (item.id == 53) {
                                    this.menuList[5].label = item.title
                                    this.menuList[5].dataList[0].title = item.children[0].title
                                } else if (item.id == 55) {
                                    this.menuList[6].label = item.title
                                    this.menuList[6].dataList[0].title = item.children[0].title
                                } else if (item.id == 57) {
                                    this.menuList[7].label = item.title
                                    this.menuList[7].dataList[0].title = item.children[0].title
                                    this.menuList[7].dataList[1].title = item.children[1].title
                                    this.menuList[7].dataList[2].title = item.children[2].title
                                }
                            })
                        } else {
                            this.$message.error(res.statusText);
                        }
                    });
            }
            this.$tools.localStorageFn.get("FIELD_LIST").forEach((item) => {
                this.menuList[1].dataList.push({
                    id: item.id,
                    title: item.name,
                    path: "/mobileareaExpertiseDetail?id=" + item.id,
                });
            });
        },

        jumpRouter(route) {
            if (this.$route.path == route) {
                this.$router.go(0);
            } else {
                this.$router.push(route);
            }
        },
    }
}
</script>

<style lang="scss" scoped>  
</style>